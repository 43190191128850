import cn from 'classnames'
import React from 'react'
import config from '../../config.json'

const PreviewGraphic = (props) => {
    const media = props['media']

    const image =
        media && !media.includes('video:')
            ? media.includes('http')
                ? media.replace(config.ipfs, config.ipfsthumbnailhash)
                : `${config.ipfsthumbnailhash}${media}`
            : null

    const video =
        media && media.includes('video:')
            ? media.includes('http')
                ? media
                      .replace('video:', '')
                      .replace(config.ipfs, config.ipfsvideohash)
                : `${config.ipfsvideohash}${media.replace('video:', '')}`
            : null

    return (
        <div className={cn('h-14 w-14 flex')}>
            {' '}
            {video ? (
                <video
                    width="50"
                    height="50"
                    loop={true}
                    autoPlay={true}
                    muted={true}
                    playsInline={true}
                    src={video}
                >
                    Your Browser does not Support Videos
                </video>
            ) : (
                <img
                    className={cn('max-w-14 h-auto w-auto max-h-14 m-auto')}
                    src={image}
                    alt="img"
                />
            )}
        </div>
    )
}

export default PreviewGraphic
