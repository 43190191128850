import cn from 'classnames'
import React from 'react'
import CollectionRankings from '../components/collection/CollectionRankings'
import { getImage } from '../components/helpers/FormatLinks'
import { NewestDrops } from '../components/newestdrops/NewestDrops'
import Page from '../components/page/Page'
import BulkToolPreview from '../components/previews/BulkToolCard'
import CollectionsPreview from '../components/previews/CollectionsPreview'
import MarketPreview from '../components/previews/MarketPreview'
import Chart from '../components/stats/Chart'
import TemplatesTable from '../components/template/TemplatesTable'
import config from '../config.json'
import { useLanguage, useQueryString } from '../hooks'

const HomePage = () => {
    const { getValue, values } = useQueryString()
    const { t } = useLanguage(values, 'common')

    const collection = getValue('collection', '')
    const image = getImage(collection)

    const className = cn(
        'p-2 md:p-5 rounded-2xl mx-0 md:mx-3 mb-5 md:mb-0 shadow',
    )

    return (
        <Page
            id={'HomePage'}
            title={config.titles.main}
            description={config.descriptions.main}
            image={image}
        >
            <div className={'mb-4'}>
                {
                    <div className={cn('content-center w-full px-2 md:px-10')}>
                        <div
                            className={cn(
                                'w-full grid grid-cols-1 lg:grid-cols-3 mt-5 mb-10',
                            )}
                        >
                            <CollectionsPreview />
                            <MarketPreview />
                            <BulkToolPreview />
                        </div>
                        <div className={cn('w-full mb-10')}>
                            <NewestDrops />
                        </div>
                        <div className={cn('mb-10')}>
                            <div
                                className={cn(
                                    'text-white text-3xl text-left p-2 lg:p-5 rounded-2xl mx-2 lg:mx-3',
                                )}
                            >
                                {t('landing.top_performers')}
                            </div>
                            <div
                                className={cn(
                                    'grid grid-cols-1 md:grid-cols-2',
                                )}
                            >
                                <CollectionRankings
                                    className={className}
                                    title={t('landing.collections')}
                                />
                                <TemplatesTable
                                    className={className}
                                    title={t('landing.assets')}
                                />
                            </div>
                        </div>

                        <div className={cn('bg-paper mb-4', className)}>
                            <div
                                className={cn(
                                    'text-primary text-xl font-bold md:font-semibold text-left rounded-2xl',
                                )}
                            >
                                {t('landing.market_volume')}
                            </div>
                            <Chart embedded={true} />
                        </div>
                    </div>
                }
            </div>
        </Page>
    )
}

export default HomePage
