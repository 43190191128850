import config from '../../config.json'
import AssetPreviewDisplay from '../assetpreviewdisplay/AssetPreviewDisplay'
import Link from '../common/util/input/Link'

export const DropTeaser = ({ drop }) => {
    const firstTemplate = drop.templatesToMint[0]

    const { idata } = firstTemplate

    const templateData = JSON.parse(idata)

    let { img, video } = templateData

    if (img) {
        img = img.includes('http') ? img : config.ipfs + img
    }

    if (video) {
        video = video.includes('http') ? video : config.ipfs + video
    }

    return (
        <Link
            className="w-full h-full rounded-lg shadow bg-paper hover:bg-paperl hover:text-primary p-5"
            href={`/drop/${drop.contract}/${drop.dropId}`}
        >
            <div className={'h-full w-full flex flex-col justify-center'}>
                <AssetPreviewDisplay
                    className="max-h-64 p-3"
                    image={img}
                    video={video}
                    previewDuration={3}
                />
                <div className="text-center">
                    {JSON.parse(drop.displayData).name.length > 30
                        ? JSON.parse(drop.displayData).name.substring(0, 30) +
                          '...'
                        : JSON.parse(drop.displayData).name}
                </div>
            </div>
        </Link>
    )
}
