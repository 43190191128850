import cn from 'classnames'
import React from 'react'

const StatsElement = (props) => {
    const { children, title, className } = props
    return (
        <div className={cn(className, 'bg-paper')}>
            <div
                className={cn(
                    'text-xl font-bold md:font-semibold text-left w-full text-primary',
                )}
            >
                {title}
            </div>

            <div className={cn('w-full')}>{children}</div>
        </div>
    )
}

export default StatsElement
