import cn from 'classnames'
import React from 'react'
import config from '../../config.json'
import Link from '../common/util/input/Link'
import { formatPercentage, formatUSD } from '../helpers/FormatLinks'
import PreviewGraphic from '../previews/PreviewGraphic'

const CollectionRankingElement = (props) => {
    const index = props['index']
    const volume = props['volume']

    const { collection, stats } = volume

    const { display_name, collection_name, image } = collection

    const { usdVolume, change, sales } = stats

    const color =
        parseFloat(change) > 0
            ? 'text-green-600'
            : parseFloat(change * 100) < 0
            ? 'text-red-600'
            : 'text-white'

    const link = (children) => {
        return <Link href={`collection/${collection_name}`}>{children}</Link>
    }

    const textClass = cn('px-2 py-4')

    return (
        <tr
            key={'ranking-detail-row' + index}
            className={cn(
                'text-white hover:text-primary hover:font-bold hover:bg-paperl transition-colors duration-300',
            )}
        >
            <td className={cn(textClass, 'text-left')}>{link(index + 1)}</td>
            <td className={cn('text-left px-4 py-2')}>
                {link(
                    <PreviewGraphic
                        media={`${config.ipfsthumbnailhash}${image}`}
                    />,
                )}
            </td>
            <td className={cn(textClass, 'text-left')}>{link(display_name)}</td>
            <td className={cn(textClass, 'text-middle')}>
                {link(formatUSD(usdVolume))}
            </td>
            <td className={cn(textClass, 'text-right', color)}>
                {link(formatPercentage(change))}
            </td>
            <td className={cn(textClass, 'text-right')}>
                {link(sales.toLocaleString(undefined))}
            </td>
        </tr>
    )
}

export default CollectionRankingElement
