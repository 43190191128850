import cn from 'classnames'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Link from '../common/util/input/Link'
import { DropTeaser } from '../dropteaser/DropTeaser'
import { get } from '../helpers/Api'

export const NewestDrops = () => {
    const [drops, setDrops] = useState([])

    const { t } = useTranslation('common')

    const parseDrops = (res) => {
        setDrops(res)
    }

    const getDrops = async () => {
        get(
            `drops?market=nfthivedrops&limit=5&verified=verified&highlight=1`,
        ).then(parseDrops)
    }
    useEffect(() => {
        getDrops()
    }, [t])

    return (
        <div className="mx-2 lg:mx-3">
            <Link href={'/market?tab=drops'}>
                <div className={cn('text-white text-3xl text-left p-2 lg:p-5')}>
                    {t('drops.latest_drops')}
                </div>
            </Link>
            <div className={'grid grid-cols-1 md:grid-cols-5 gap-5'}>
                {drops && drops.map((drop) => <DropTeaser drop={drop} />)}
            </div>
        </div>
    )
}
