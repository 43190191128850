import React from 'react'
import { useTranslation } from 'react-i18next'
import PreviewCard from './PreviewCard'

const BulkToolPreview = ({ className }) => {
    const { t } = useTranslation('common')

    return (
        <PreviewCard
            title={t('landing.bulk_title')}
            link={'/bulkoverview'}
            background={
                'bg-gradient-to-bl from-card3 to-card4 hover:shadow-bulk transition-shadow duration 200'
            }
            className={className}
            backgroundClass={'bg-graphic1'}
        >
            <div>{t('landing.bulk_info')}</div>
        </PreviewCard>
    )
}

export default BulkToolPreview
