import cn from 'classnames'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import config from '../../config.json'
import { get } from '../helpers/Api'
import LoadingIndicator from '../loadingindicator/LoadingIndicator'
import CollectionRankingElement from './CollectionRankingElement'
import StatsElement from './StatsElement'

const CollectionRankings = (props) => {
    const [collectionVolumes, setCollectionVolumes] = useState(null)
    const [isLoading, setIsLoading] = useState(true)

    const { title, className } = props
    const { t } = useTranslation('common')

    const getCollectionVolume = async () => {
        return get(
            `top-collections/1?limit=${config.landingpage.elementcount}`,
            'v1',
        )
    }

    const parseCollectionVolume = (res) => {
        setCollectionVolumes(res)
        setIsLoading(false)
    }

    useEffect(() => {
        getCollectionVolume().then((res) => parseCollectionVolume(res))
    }, [])

    const textClass = cn('px-2 py-4')

    return (
        <StatsElement title={title} className={className}>
            <div
                className={cn('w-full h-full overflow-x-auto')}
                id={'CollectionRankings'}
            >
                {isLoading ? (
                    <LoadingIndicator />
                ) : (
                    <table
                        className={cn(
                            'w-full my-auto text-sm font-normal',
                            'overflow-hidden',
                            'whitespace-nowrap',
                        )}
                    >
                        <thead>
                            <tr
                                className={cn(
                                    'font-bold md:font-semibold text-main',
                                )}
                            >
                                <td className={cn(textClass, 'text-left')}></td>
                                <td className={cn('text-left px-4 py-2')}></td>
                                <td className={cn(textClass, 'text-left')}>
                                    {t('landing.name_col')}
                                </td>
                                <td className={cn(textClass, 'text-middle')}>
                                    {t('landing.volume_col')}
                                </td>
                                <td className={cn(textClass, 'text-right')}>
                                    {t('landing.change_col')}
                                </td>
                                <td className={cn(textClass, 'text-right')}>
                                    {t('landing.sales_col')}
                                </td>
                            </tr>
                        </thead>
                        <tbody className="overflow-y-auto">
                            {collectionVolumes &&
                                collectionVolumes.map((volume, index) => (
                                    <CollectionRankingElement
                                        key={
                                            'CollectionRankingElement-' + index
                                        }
                                        volume={volume}
                                        index={index}
                                    />
                                ))}
                        </tbody>
                    </table>
                )}
            </div>
        </StatsElement>
    )
}

export default CollectionRankings
