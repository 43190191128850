import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { get } from '../helpers/Api'
import PreviewCard from './PreviewCard'

const SetsPreview = ({ className }) => {
    const [isLoading, setIsLoading] = useState(true)
    const [numCollections, setNumCollections] = useState(null)
    const { t } = useTranslation('common')

    const parseNumberOfCollections = (res) => {
        setNumCollections(res['numberOfCollections'])
        setIsLoading(false)
    }

    const getNumberOfCollections = async () => {
        return await get('num-collections', 'api').then(
            parseNumberOfCollections,
        )
    }

    useEffect(() => {
        getNumberOfCollections()
    })

    return (
        <PreviewCard
            title={t('landing.collections_title')}
            link={'/collections'}
            background={
                'bg-gradient-to-bl from-card5 to-card6 hover:shadow-sets transition-shadow duration 200'
            }
            className={className}
            backgroundClass={'bg-graphic3'}
        >
            {!isLoading && (
                <div>
                    {t('landing.collection_info', {
                        collections: numCollections,
                    })}
                </div>
            )}
        </PreviewCard>
    )
}

export default SetsPreview
