import cn from 'classnames'
import React from 'react'
import Link from '../common/util/input/Link'

const PreviewCard = ({
    link,
    title,
    children,
    background = 'bg-paper',
    backgroundClass,
}) => {
    return (
        <Link
            href={link}
            className={cn(
                background,
                'h-44 md:h-26 hover:text-shine mx-0 md:mx-3 mb-5 lg:mb-0 shadow rounded-2xl',
            )}
        >
            <div
                className={cn(
                    'w-full h-full p-2 md:p-5 bg-right-top bg-75% bg-no-repeat rounded-2xl',
                    backgroundClass,
                )}
            >
                <div
                    className={cn(
                        'text-3xl md:text-1xl mb-2 lg:text-3xl text-neutral text-left',
                    )}
                >
                    {title}
                </div>
                <div className={'w-full flex'}>
                    <div
                        className={cn(
                            'text-left text-neutral text-sm lg:text-xs xl:text-sm 2xl:text-base break-words w-2/3',
                        )}
                    >
                        {children}
                    </div>
                </div>
            </div>
        </Link>
    )
}

export default PreviewCard
