import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { get } from '../helpers/Api'
import { formatNumber } from '../helpers/FormatLinks'
import PreviewCard from './PreviewCard'

const MarketPreview = () => {
    const [isLoading, setIsLoading] = useState(true)
    const [numSales, setNumSales] = useState(null)

    const { t } = useTranslation('common')

    const parseNumberOfListings = (res) => {
        setNumSales(res && res['numberOfSales'])
        setIsLoading(false)
    }

    const getNumberOfListings = async () => {
        return await get('num-sales', 'v1').then(parseNumberOfListings)
    }

    useEffect(() => {
        getNumberOfListings()
    })

    return (
        <PreviewCard
            background={
                'bg-gradient-to-bl from-card1 to-card2 hover:shadow-market transition-shadow duration 200'
            }
            title={t('landing.market_title')}
            link={'/market'}
            backgroundClass={'bg-graphic2'}
        >
            {!isLoading &&
                t('landing.market_info', {
                    x: formatNumber(numSales),
                })}
        </PreviewCard>
    )
}

export default MarketPreview
