import cn from 'classnames'
import React from 'react'
import config from '../../config.json'
import Link from '../common/util/input/Link'
import { formatPercentage, formatUSD } from '../helpers/FormatLinks'
import PreviewGraphic from '../previews/PreviewGraphic'

const TemplatesTableElement = (props) => {
    const index = props['index']
    const topTemplate = props['topTemplate']

    const { template, stats, collection } = topTemplate

    const { img, video, name, template_id } = template

    const { collection_name } = collection

    const { volume, cheapest, change, sales } = stats

    const color =
        parseFloat(change) > 0
            ? 'text-green-600'
            : parseFloat(change * 100) < 0
            ? 'text-red-600'
            : 'text-white'

    const previewPicUrl = img
        ? `${config.ipfs}${img}`
        : `video:${config.ipfs}${video}`

    const link = (children) => {
        return template_id ? (
            <Link
                href={`market?template_id=${template_id}&collection=${collection_name}&order_by=offer_asc&limit=100&exact_search=true`}
            >
                {children}
            </Link>
        ) : (
            children
        )
    }

    const textClass = cn('px-2 py-4')

    return (
        <tr
            key={'ranking-detail-row' + index}
            className={cn(
                'text-white hover:text-primary hover:font-bold hover:bg-paperl transition-colors duration-300',
            )}
        >
            <td className={cn(textClass, 'text-left')}>{link(index + 1)}</td>
            <td className={cn('text-left px-4 py-2')}>
                {link(<PreviewGraphic media={previewPicUrl} />)}
            </td>
            <td className={cn(textClass, 'text-left')}>{link(name)}</td>
            <td className={cn(textClass, 'text-center')}>
                {link(cheapest ? formatUSD(cheapest) : 'n/a')}
            </td>
            <td className={cn(textClass, 'text-center')}>
                {link(formatUSD(volume))}
            </td>
            <td className={cn(textClass, 'text-right', color)}>
                {link(formatPercentage(change))}
            </td>
            <td className={cn(textClass, 'text-right')}>
                {link(sales.toLocaleString(undefined))}
            </td>
        </tr>
    )
}

export default TemplatesTableElement
